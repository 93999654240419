import React, { useCallback, useMemo } from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faHeart,
  faShare,
} from '@fortawesome/free-solid-svg-icons';

import { Video } from '../../../types';
import styles from './style';

interface ActionsProps {
  innerHeight: number;
  openShortModal: () => void;
  video: Video;
  os: string;
}

const Actions = React.memo((props: ActionsProps) => {
  const {
    innerHeight,
    openShortModal,
    os,
    video,
  } = props;

  const getBottomRightGrid = useCallback(() => {
    let bottom = '24%';
    if (innerHeight < 660 && os === 'ios') {
      bottom = '40%';
    } else if (innerHeight < 660 && os === 'android') {
      bottom = '40%';
    } else if (
      innerHeight >= 660
      && innerHeight <= 734
      && os === 'ios'
    ) {
      bottom = '30%';
    }
    return bottom;
  }, [innerHeight, os]);

  const actionsRow = useMemo(() => ({
    height: '25%',
    position: 'absolute',
    right: '4%',
    width: '10%',
    bottom: getBottomRightGrid(),
    zIndex: 10,
  }), [getBottomRightGrid]);

  const avatar = useMemo(() => (`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}${video.user.avatar}`), []);
  const comments = useMemo(() => {
    return Math.floor(Math.random() * 3) + 1;
  }, []);
  const shares = useMemo(() => {
    return Math.floor(Math.random() * 4) + 2;
  }, []);

  return (
    <Grid
      item
      xs={12}
      display="flex"
      sx={actionsRow}
    >
      <Grid container height={styles.actionsContainerRow}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={openShortModal}
        >
          <Avatar
            alt="profile"
            src={avatar}
            sx={styles.avatarProfile}
          />
        </Grid>
        <Grid
          item
          xs={12}
          mt={'1rem'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={openShortModal}
        >
          <FontAwesomeIcon
            icon={faHeart}
            style={styles.heartIcon}
            color="white"
          />
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Typography
            variant="body2"
            align="center"
            sx={styles.likeTypo}
          >
            {video.likes}
          </Typography>
        </Grid>
        <Grid
          mt={'0.5rem'}
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={openShortModal}
        >
          <FontAwesomeIcon
            icon={faComment}
            style={styles.commentIcon}
            color="white"
          />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="body2"
            align="center"
            sx={styles.likeTypo}
          >
            {video.comments}
          </Typography>
        </Grid>
        <Grid
          mt={'0.75rem'}
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={openShortModal}
        >
          <FontAwesomeIcon
            icon={faShare} style={styles.shareIcon}
            color="white"
          />
        </Grid>
        <Grid
          item
          xs={12}
          mt={'0.2rem'}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="body2"
            align="center"
            sx={styles.likeTypo}
          >
            {video.shares}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Actions;
